export const textToSigns = function(text){
  switch (text) {
    case 'add': return '+';
    case 'substract': return '-';
    case 'multiply': return 'x';
    case 'divide': return '÷';
    case 'equal': return '=';
    default: return text;
  }
}

export async function sendText(textData) {
    const formData = new FormData();
    formData.append('textData', textData);
    const response = await fetch('/textClassification', {
        method: "POST",
        body: formData
      });
    return response.text()
}

export const playCameraStream = (videoRef) => {
  if (videoRef.current) {
    videoRef.current.play();
  }
};

export const captureImageFromCamera = (canvasRef, imageRef, videoRef) => {
  const context = canvasRef.current.getContext('2d');
  const { videoWidth, videoHeight } = videoRef.current;

  canvasRef.current.width = videoWidth;
  canvasRef.current.height = videoHeight;

  context.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);

  canvasRef.current.toBlob((blob) => {
      imageRef.current = blob;
  })
};
