import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import ReactGA from "react-ga4";

import 'bootstrap/dist/css/bootstrap.min.css';
import MainDisplay from './blocks/mainDisplay';
import { HookProvider } from './blocks/hookContext';
const TRACKING_ID = "G-RWZQGKGL8F"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <Router>
      <div className="fullform">
        <div className="titleform">
          <h1>Ready Teddy Go!</h1>
        </div>

        <div className="mainWindow">
          <Switch>
            <Route path="/:lang" render={(props) => (
              <HookProvider>
                {/* Passing language parameter to MainDisplay */}
                <MainDisplay lang={props.match.params.lang} />
              </HookProvider>
            )} />
            {/* Redirect base URL to default language (e.g., English) */}
            <Redirect from="/" to="/en" />
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;