import React from 'react';
import Select from 'react-select';
import { Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import TypeOfAnimationBlock from './typeOfAnimationBlock';
import { Reorder } from "framer-motion";
import { useHookContext } from './hookContext';
import { scenariosDict } from '../support/dictionaries';
import { textToSigns } from '../support/funcs';
import {translateButton} from '../support/dictionaries';
export default function MainDisplay(props) {
    const { lang } = props; // Get language from props

    // print the lang in console
    console.log(lang);
    const {playPause, setPlayPause,
        curVideo, setCurVideo,
        scenarios, setScenarios,
        playSequence, setPlaySequence,
        declineBtn, setDeclineBtn,
        acceptBtn, setAcceptBtn,
        currentlyPlaying, setCurrentlyPlaying,
        typeOfAnimation, setTypeOfAnimation,
    ...rest} = useHookContext();

    const playNext = function() {
        resetReactions();
        if (scenariosDict[scenarios[0]] === scenariosDict[scenarios[1]]) {
            setPlayPause(true);
        }
        if (playPause) {
            setCurVideo(scenariosDict['idle']);
            setPlayPause(false);
        } else {
            setCurVideo(scenariosDict[scenarios[0]]);
            setScenarios(scenarios.slice(1));
        }
        setCurrentlyPlaying(true);
    }

    const playAll = function() {
        setPlaySequence(true);
        playNext();
    }
    
    const resetReactions = function() {
        setAcceptBtn('');
        setDeclineBtn('');
    }

    const reactToAnimation = function(reaction) {
        if (reaction === 'accept') {
            setAcceptBtn('accepted');
            setDeclineBtn('');
        } else {
            setAcceptBtn('');
            setDeclineBtn('declined');
        }
    }

    const typesOfAnimationList = [
        { value: 'face', label: translateButton('face', lang) },
        { value: 'body', label: translateButton('body', lang) },
        { value: 'count', label: translateButton('count', lang) },
        { value: 'room', label: translateButton('room', lang) },
        { value: 'bedroom', label: translateButton('bedroom', lang) },
        { value: 'bedroom_waking_up', label: translateButton('bedroom_waking_up', lang) },
        { value: 'kitchen1', label: translateButton('kitchen1', lang) },
        { value: 'kitchen2', label: translateButton('kitchen2', lang) },
        { value: 'kitchen3', label: translateButton('kitchen3', lang) },
        { value: 'garden', label: translateButton('garden', lang) }
    ];

    return (
        <div className = 'mainBox'>
            <div>
                <div className='videoBox'>
                    <div className='player-wrapper'>
                    <ReactPlayer
                        className='react-player'
                        url= {curVideo}
                        fallback={scenariosDict['idle']}
                        width='100%'
                        height='100%'
                        controls = {true}
                        muted
                        playing
                        onEnded={() => {
                            setCurrentlyPlaying(false);
                            if (playSequence) {
                                if (scenarios.length) {
                                    playNext();
                                } else {
                                    setCurVideo(scenariosDict.idle);
                                    setPlaySequence(false);
                                }
                            }
                        }}
                    />    
                    </div>

                </div>

                <div className='buttonGridMarks'>
                    <Button 
                        className={acceptBtn}
                        type='button' 
                        variant='success'
                        value='accept' 
                        size='md'
                        onClick={(value) => {
                            reactToAnimation(value.target.value);
                            setCurVideo(scenariosDict.thumbsUp);
                        }}
                    >
                    {translateButton("Thumbs up",lang)}
                    </Button>

                    <Button 
                        className={declineBtn}
                        type='button' 
                        variant='danger'
                        value='decline' 
                        size='md'
                        onClick={(value) => {
                            reactToAnimation(value.target.value);
                            setCurVideo(scenariosDict.no);
                        }}               
                        >
                    {translateButton("No",lang)}
                    </Button>
                </div>
            </div>
            <div>
                <div>
                     <div className="buttonGridOptions">
                <Button 
                    type='button' 
                    variant='primary'
                    value='Play next' 
                    size='md'
                    onClick={() => playNext()}
                >
                {translateButton("Play next",lang)}
                </Button>

                <Button 
                    type='button' 
                    variant='primary'
                    value='Play sequence' 
                    size='md'
                    onClick={() => playAll()}
                >
                {translateButton("Play sequence",lang)}
                </Button>
            </div>

                    <label>{translateButton("Select the type of scenarios:",lang)} </label>
                    <Select 
                        options = {typesOfAnimationList} 
                        className='setsSelector'
                        placeholder='Select...'
                        value={typeOfAnimation}
                        onChange={value => {setTypeOfAnimation(value)}}
                    />
                    <TypeOfAnimationBlock lang={lang} />
                </div>
                <div>
                    
                        
                   
                </div>
            </div>
            <div className='queueColumn'>
                <label>{translateButton("Queue:",lang)}</label>
                    <Reorder.Group as='div' values={scenarios} onReorder={setScenarios}>
                        {scenarios.map((elem, idx) => (
                            <Reorder.Item as='div' key={elem} value={elem} drag='y'>
                                <Button
                                    className={`orderedItem`}
                                    type='none' 
                                    variant='info'
                                    value={elem + idx}
                                    size='md'
                                    onDoubleClick={() => {
                                        const result = [];
                                        for (const [index, scenario] of scenarios.entries()) {
                                            if (index !== idx) {
                                                result.push(scenario);
                                            }
                                        }
                                        console.log(elem);
                                        setCurrentlyPlaying(true);
                                        setScenarios(result);
                                    }}
                                    >
                                {
                                translateButton(elem,lang)
                                ///\d/.test(elem) ? elem.match(/\d/g).join('') : textToSigns(elem)
                                }
                                </Button>
                            </Reorder.Item>
                        ))}
                    </Reorder.Group>  
                </div>
        </div>
    )
}
