import React, {useContext, useState} from 'react'
import { scenariosDict } from '../support/dictionaries';

const HookContext = React.createContext();

export const useHookContext = () => {
    return useContext(HookContext)
}

export const HookProvider = ({ children }) => {

    const [playPause, setPlayPause] = useState(false);
    const [curVideo, setCurVideo] = useState(scenariosDict.idle);
    const [scenarios, setScenarios] = useState([]);
    const [playSequence, setPlaySequence] = useState(false);
    const [declineBtn, setDeclineBtn] = useState('');
    const [acceptBtn, setAcceptBtn] = useState('');
    const [currentlyPlaying, setCurrentlyPlaying] = useState(false);
    const [typeOfAnimation, setTypeOfAnimation] = useState({ value: 'body', label: 'Body' });

    return(
        <HookContext.Provider value = {{
            playPause, setPlayPause,
            curVideo, setCurVideo,
            scenarios, setScenarios,
            playSequence, setPlaySequence,
            declineBtn, setDeclineBtn,
            acceptBtn, setAcceptBtn,
            currentlyPlaying, setCurrentlyPlaying,
            typeOfAnimation, setTypeOfAnimation
        }}>
            { children }
        </HookContext.Provider>
    )
}