import React from 'react'
import { Button } from 'react-bootstrap';
import { useHookContext } from './hookContext'
import { scenarioCountButtonList, scenarioBodyButtonList, scenarioHeadButtonList, 
    scenarioRoomButtonList, scenarioChoosingClothesList, scenario1KitchenList,
scenario2KitchenList, scenario3KitchenList, scenarioWakingUpList,scenarioGardenList,
buttonsTranslator,translateButton,translateTip} from '../support/dictionaries';
import { textToSigns } from '../support/funcs';

export default function TypeOfAnimationBlock(props) {
    console.log(props);
    const { lang } = props; // Receive the lang prop

    const {setScenarios,
        typeOfAnimation,
        ...rest} = useHookContext()
 
    const pushTheScenario = function(scenario) {
        setScenarios(oldScenarios => [...oldScenarios, scenario])
    }
 
    if (typeOfAnimation.value === 'face') {
        return (
            <div>
                <label>{translateButton("Face parts exposition:",lang)}</label>
                <div className='buttonGridOptions'>
                    {scenarioHeadButtonList.map((elem, idx) => (
                        <Button 
                            id={elem + idx}
                            type='button' 
                            variant='primary'
                            value= {elem}
                            size='md'
                            title={translateTip(elem, lang)}
                            onClick={value => {
                                pushTheScenario(value.target.value)
                            }}
                        >
                        {translateButton(elem, lang)}
                        </Button>
                    ))}
                </div>
            </div>
        )
    } else if (typeOfAnimation.value === 'body') {
        return (
            <div>
                <label>{translateButton("Body parts exposition:", lang)}</label>
                <div className='buttonGridOptions'>
                    {scenarioBodyButtonList.map((elem, idx) => (
                        <Button 
                            id={elem + idx}
                            type='button' 
                            variant='primary'
                            value={elem}
                            size='md'
                            title={translateTip(elem, lang)}
                            onClick={value => pushTheScenario(value.target.value)}
                        >
                        {translateButton(elem, lang)}
                        </Button>
                    ))}
                </div>
            </div>
        )
    } else if (typeOfAnimation.value === 'count') {
        return (
            <div>
                <label> {translateButton("Count commands:", lang)}</label>
                <div className='buttonGridOptions'>
                    {scenarioCountButtonList.map((elem, idx) => (
                        <Button 
                            id={elem + idx}
                            type='button' 
                            variant='primary'
                            value={elem}
                            size='md'
                            title={translateTip(elem, lang)}
                            onClick={value => pushTheScenario(value.target.value)}
                        >
                        {translateButton(elem, lang)}
                        </Button>
                    ))}
                </div>
            </div>
        )
    } else if(typeOfAnimation.value === 'bedroom') {
        return (
            <div>
                <label>{translateButton("Teddy choosing clothes scenario:",lang)}</label>
                <div className='buttonGridOptions'>
                    {scenarioChoosingClothesList.map((elem, idx) => (
                        <Button 
                            id={elem + idx}
                            type='button' 
                            variant='primary'
                            value= {elem}
                            size='md'
                            title={translateTip(elem, lang)}
                            onClick={value => pushTheScenario(value.target.value)}
                        >
                        {translateButton(elem, lang)}
                        </Button>
                    ))}
                </div>
            </div>
        )
    } else if(typeOfAnimation.value === 'bedroom_waking_up') {
        return (
            <div>
                <label>{translateButton("Teddy waking up scenario:",lang)}</label>
                <div className='buttonGridOptions'>
                    {scenarioWakingUpList.map((elem, idx) => (
                        <Button 
                            id={elem + idx}
                            type='button' 
                            variant='primary'
                            value= {elem}
                            size='md'
                            title={translateTip(elem, lang)}
                            onClick={value => pushTheScenario(value.target.value)}
                        >
                        {translateButton(elem, lang)}
                        </Button>
                    ))}
                </div>
            </div>
        )
    } else if (typeOfAnimation.value === 'room') {
        return (
            <div>
                <label>{translateButton("Teddy in-room activities:",lang)}</label>
                <div className='buttonGridOptions'>
                    {scenarioRoomButtonList.map((elem, idx) => (
                        <Button 
                            id={elem + idx}
                            type='button' 
                            variant='primary'
                            value= {elem}
                            size='md'
                            title={translateTip(elem, lang)}
                            onClick={value => pushTheScenario(value.target.value)}
                        >
                        {translateButton(elem, lang)}
                        </Button>
                    ))}
                </div>
            </div>
        )
    } else if (typeOfAnimation.value === 'kitchen1') {
        return (
            <div>
                <label>{translateButton("Introduce Kitchenware (knives,spoons):",lang)}</label>
                <div className='buttonGridOptions'>
                    {scenario1KitchenList.map((elem, idx) => (
                        <Button 
                            id={elem + idx}
                            type='button' 
                            variant='primary'
                            value= {elem}
                            size='md'
                            title={translateTip(elem, lang)}
                            onClick={value => pushTheScenario(value.target.value)}
                        >
                        {translateButton(elem, lang)}
                        </Button>
                    ))}
                </div>
            </div>
        )
    } else if (typeOfAnimation.value === 'kitchen2') {
        return (
            <div>
                <label>{translateButton("Sorting Items (Fridge, Cupboard):",lang)}</label>
                <div className='buttonGridOptions'>
                    {scenario2KitchenList.map((elem, idx) => (
                        <Button 
                            id={elem + idx}
                            type='button' 
                            variant='primary'
                            value= {elem}
                            size='md'
                            title={translateTip(elem, lang)}
                            onClick={value => pushTheScenario(value.target.value)}
                        >
                        {translateButton(elem, lang)}
                        </Button>
                    ))}
                </div>
            </div>
        )
    } else if (typeOfAnimation.value === 'kitchen3') {
        return (
            <div>
                <label>{translateButton("Prepare Breakfast:",lang)}</label>
                <div className='buttonGridOptions'>
                    {scenario3KitchenList.map((elem, idx) => (
                        <Button 
                            id={elem + idx}
                            type='button' 
                            variant='primary'
                            value= {elem}
                            size='md'
                            title={translateTip(elem, lang)}
                            onClick={value => pushTheScenario(value.target.value)}
                        >
                        {translateButton(elem, lang)}
                        </Button>
                    ))}
                </div>
            </div>
        )
    } else if (typeOfAnimation.value === 'garden') {
        return (
            <div>
                <label>{translateButton("Garden:",lang)}</label>
                <div className='buttonGridOptions'>
                    {scenarioGardenList.map((elem, idx) => (
                        <Button 
                            id={elem + idx}
                            type='button' 
                            variant='primary'
                            value= {elem}
                            size='md'
                            title={translateTip(elem, lang)}
                            onClick={value => pushTheScenario(value.target.value)}
                        >
                        {translateButton(elem, lang)}
                        </Button>
                    ))}
                </div>
            </div>
        )
    }
}
